import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AppBar as MaterialBar, Toolbar,
  Box, Tabs, Tab, IconButton, Button,
  Menu, MenuItem, Hidden,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineBell } from 'react-icons/ai';
import { RiArrowDownSFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import isEqual from 'lodash/isEqual';

import Typography from '../../componentes/Typography';
import { MENU_OPCIONES, quitarPermisos, setOpcionActiva, setPermisos } from '../../ducks/appbar';
import { removeToken } from '../../ducks/user';

import axios from '../../configuraciones/axios';
import { USUARIO_NO_PERMISO } from '../../configuraciones/mensajes';
import endpoints from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';

import styles from './styles'; 

const AppBar = () => {
  const classes = styles();
  const { indexActivo, permisos, user, ocultarOpciones } = useSelector(
    ({
      appbar: { indexActivo, permisos, ocultarOpciones },
      user: { user }
    }) => ({ indexActivo, permisos, user, ocultarOpciones }),
    (prev, next) => {
      return (
        prev.indexActivo === next.indexActivo &&
        prev.ocultarOpciones === next.ocultarOpciones &&
        isEqual(prev.permisos, next.permisos) &&
        prev.user.perfilID === next.user.perfilID &&
        prev.user.nombre === next.user.nombre
      );
    }
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [userAnchor, setUserAnchor] = useState(null);

  const modulosDisponibles = useMemo(() => {
    const permisosArr = permisos.map(({ moduloID }) => moduloID);

    return MENU_OPCIONES.filter((item) => {
      if (item?.submenu) {
        return item.submenu.some(({ id }) => permisosArr.includes(id));
      }
  
      return permisosArr.includes(item?.id);
    })
  }, [permisos]);

  const handleClick = useCallback((opcion) => {
    if ( permisos.map(permiso => permiso.moduloID).includes(opcion.id) || !opcion.id ){
      sessionStorage.setItem('tabIndex', opcion.index);
      dispatch(setOpcionActiva(opcion.index));
      history.replace(opcion.path)
    }
    else {
      toast.error(USUARIO_NO_PERMISO);
    }
  }, [dispatch, history, permisos])

  const cerrarSesion = useCallback(() => {
    dispatch(removeToken());
    dispatch(quitarPermisos());
    sessionStorage.clear();
    localStorage.clear();
  }, [dispatch]);

  useEffect(() => {
    if (permisos.length === 0) {
      axios.get(endpoints.permisosAsignados(user.perfilID)).then((data) => {
        dispatch(setPermisos(data));
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <MaterialBar position="static" className={classes.root}>
        <Toolbar>
          <Box flex={1} style={{ height: '64px', display: 'flex' }} flexDirection="row">
            <Box className={classes.logoContainer}>
              <img
                alt="logo"
                src="/assets/images/logoblue.png"
                className={classes.logo} />
            </Box>
            {!ocultarOpciones && <Hidden smDown>
              <Box flex={4}>
                <Tabs
                  value={indexActivo}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  {modulosDisponibles.map((opcion, index) => (
                    <Tab 
                      key={index}
                      label={opcion.label}
                      className={classes.tab}
                      onClick={() => handleClick(opcion)}
                    />
                  ))}
                </Tabs>
              </Box>
            </Hidden>}
            <Box flex={1} className={classes.userContainer}>
              <Menu
                keepMounted={false}
                open={Boolean(userAnchor)}
                anchorEl={userAnchor}
                onClose={() => setUserAnchor(null)}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <MenuItem onClick={cerrarSesion}>Cerrar sesión</MenuItem>
              </Menu>
              <div className={classes.iconoContainer}>
                <IconButton>
                  <AiOutlineBell color={paleta.bar.icono} />
                </IconButton>
              </div>
              <Button
                variant="text"
                className={classes.user}
                onClick={({ currentTarget }) => setUserAnchor(currentTarget)}>
                <Typography>{user.nombre}</Typography>
                <RiArrowDownSFill />
              </Button>
            </Box>
          </Box>
        </Toolbar>
        {!ocultarOpciones && <Hidden mdUp>
          <Toolbar style={{ overflow: 'auto' }}>
            <Box flex={4}>
              <Tabs
                value={indexActivo}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {modulosDisponibles.map((opcion, index) => (
                  <Tab
                    key={index}
                    label={opcion.label}
                    className={classes.tab}
                    onClick={() => handleClick(opcion)}
                  />
                ))}
              </Tabs>
            </Box>
          </Toolbar>
        </Hidden>}
      </MaterialBar>
    </div>
  );
};

export default React.memo(AppBar);
