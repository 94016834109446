import React from 'react';
import { AppBar, Grid } from '@material-ui/core';

import { APP_NAME } from '../../configuraciones/general';
import styles from './styles';

const Footer = () => {
  const classes = styles();
  const [appnName1, appnName2] = APP_NAME.split(' ');

  return (
    <AppBar className={classes.appBar}>
      <Grid container className={classes.footer}>
        <Grid item>
          <img src='/assets/images/hoja.png' alt='icono' width='35' height='35' />
        </Grid>
        <Grid item>
          <Grid container alignItems='baseline'>
            <h1>&nbsp;{appnName1}</h1>
            {appnName2 && <h2>&nbsp;{appnName2}</h2>}
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Footer;
