/* eslint no-restricted-syntax: 0 */
/* eslint guard-for-in: 0 */
/* eslint no-param-reassign: 0 */
import moment from 'moment';
import puntoEnPoligono from 'point-in-polygon';
import { toast } from 'react-toastify';

import 'moment/locale/es';

import axios from '../configuraciones/axios';
import { VALIDAR_SELECCION_REPORTE } from '../configuraciones/mensajes';
import config from '../configuraciones/general';

import Regex, { regexMessages } from './regex';

moment.locale('es');

export const removeByVal = (obj, val) => {
  for (const key in obj) {
    if ((obj[key] && (obj[key] === val || obj[key].toString() === val.toString()))) delete obj[key];
  }
  return obj;
};

export const findPropertysEmpty = (obj, showMsg = false) => {
  const errors = {};
  const regex = {};
  const elements = obj?.querySelectorAll('input, textarea, select') || [];
  let totalErrors = 0;
  let totalRegex = 0;
  Array.prototype.slice.call(elements).forEach((element) => {
    const regexElement = element.getAttribute('regex');
    const regexOnSubmit = element.getAttribute('regexonsubmit');
    const index = element.getAttribute('index');
    const equals = element.getAttribute('equals');
    if (regex && regexOnSubmit && element.value.trim() !== '') {
      const validation = Regex[regexElement];
      const regexValid = validation(element.value, equals);
      if (!regexValid) {
        if (index) {
          regex[`${element.name}${index}`] = regexMessages[regexElement] || true;
        } else {
          regex[element.name] = regexMessages[regexElement] || true;
        }
        totalRegex++;
      }
    }
    if (!element.required) return;
    const type = typeof (element.value);
    const errorName = element.getAttribute('errorname');
    if (type === 'string') {
      if (element.value.trim() === '' || element.value.trim() === '-1') {
        totalErrors++;
        errors[errorName || element.name] = true;
      }
    } else if (type === 'number') {
      if (element.value === -1) {
        totalErrors++;
        errors[errorName || element.name] = true;
      }
    }
  });

  for (const key in errors) {
    if (key.includes('.')) {
      const arr = key.split('.');
      if (!errors[arr[0]]) errors[arr[0]] = {};
      errors[arr[0]][arr[1]] = errors[key];
      delete errors[key];
    }
  }

  if (showMsg && (totalErrors > 0 || totalRegex > 0)) {
    toast.error(`${totalErrors + totalRegex} campos requeridos no han sido completados.`);

  };

  return {
    errors, totalErrors, regex, totalRegex,
  };
};

export const objToFormData = (obj) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    let valor = '';
    if (Array.isArray(obj[key]) || typeof obj[key] === 'object') valor = JSON.stringify(obj[key]);
    else valor = obj[key];
    formData.append(key, valor);
  });
  return formData;
};

export const getDifferences = (original, update) => {
  const differences = {};
  Object.keys(update)
    .forEach((key) => {
      if (original[key] !== update[key]) {
        differences[key] = original[key];
      }
    });
  return differences;
}

export const fileToBase64 = (file) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.readAsDataURL(file);
})

export const getMimeType = (name) => {
  const split = name?.split('.');
  return split[split.length - 1]
};

export const trim = (obj) => {
  const objTrim = {};

  // Eliminamos espacios en blanco de la propiedades
  for (const [key, value] of Object.entries(obj)) {
    objTrim[key] = typeof value === 'string' && key !== 'password' ? value.trim() : value
  };

  return {
    ...objTrim
  };
}

export const getISOWeekDates = (isoWeekNum = 1, format = '') => {
  let d = moment()
    .isoWeek(1)
    .startOf('isoWeek')
    .add(isoWeekNum - 1, 'weeks');
  for (var dates = [], i = 0; i < 7; i++) {
    const p = d.clone().format(format);
    dates.push(p);
    d.add(1, 'day');
  }
  return dates;
};
export const getTheDaysOfTheWeek = (isoWeekNum = 1, format = '') => {
  let d = moment()
    .isoWeek(1)
    .startOf('isoWeek')
    .add(isoWeekNum - 1, 'weeks');
  for (var dates = [], i = 0; i < 7; i++) {
    const nombre = d.clone().format(format);
    const dia = d.clone().format('YYYY-MM-DD')
    dates.push({ id: i, nombre, dia: dia });
    d.add(1, 'day');
  }
  return dates;
};

export const getWeeks = () => {
  const semanas = [];
  for (let i = 1; i <= moment().weeksInYear(); i++) {
    semanas.push({
      id: i,
      fechaObjetivo: moment().subtract(i, 'weeks').format('W-YYYY'),
      nombre: `${i} - ${moment().isoWeek(i).startOf("isoweek").format('DD-MMM-YYYY')}
       /  ${moment().isoWeek(i).endOf("isoweek").format('DD-MMM-YYYY')}`,
    });
  }

  return semanas;
};

export const getWeeksFormatted = () => {
  const semanas = [];
  for (let i = 0; i < moment().weeksInYear(); i++) {
    semanas.push({
      id: i,
      semanaID: moment().subtract(i, 'weeks').isoWeek(),
      nombre: `${moment().subtract(i, 'weeks').isoWeek()} (${moment()
        .subtract(i, 'weeks')
        .startOf('isoWeek')
        .format('DD-MMM-YYYY')} / ${moment()
          .subtract(i, 'weeks')
          .endOf('isoWeek')
          .format('DD-MMM-YYYY')})`,
    });
  }

  return semanas;
};

export const sumarArr = (arr, key) => {
  const valores = arr.map(e => e[key]);
  return valores.reduce((a, b) => Number(a) + Number(b), 0);
}

export const parseFloatProperties = (obj) => {
  const arr = Object.entries(obj);
  const parseValues = arr.map(e => {
    const value = parseFloat(e[1]);
    if (!Number.isNaN(value)) {
      return [e[0], parseFloat(e[1])];
    }
    return e;
  })
  return Object.fromEntries(parseValues);
}

export const ValidarRangos = (rangos, id) => {
  const listaRangos = Object.entries(rangos)
    .filter(rango => rango[0].includes('rango'))
    .map(rango => Number(rango[1]));

  // Validación de tipo de datos y valores nulos
  if (listaRangos.some(rango => rango === null || isNaN(Number(rango)))) {
    return id;
  }

  // Conversión de rangos a números para facilitar comparaciones
  const [rojoMin, rojoMax, amarilloMin, amarilloMax, verdeMin, verdeMax] = listaRangos;

  // Validación de rangos positivos
  if (rojoMin < 0 || rojoMax < 0 || amarilloMin < 0 || amarilloMax < 0 || verdeMin < 0 || verdeMax < 0) {
    return id;
  }

  // Validación de rangos internos
  if (rojoMin >= rojoMax || amarilloMin >= amarilloMax || verdeMin >= verdeMax) {
    return id;
  }

  // Validación de no solapamiento
  const rangosOrdenados = [
    { min: rojoMin, max: rojoMax },
    { min: amarilloMin, max: amarilloMax },
    { min: verdeMin, max: verdeMax }
  ].sort((a, b) => a.min - b.min);

  for (let i = 0; i < rangosOrdenados.length - 1; i++) {
    if (rangosOrdenados[i].max >= rangosOrdenados[i + 1].min) {
      return id;
    }
  }

  // Si todas las validaciones son exitosas
  return;
}

export const capitalizarPalabras = (string) => {
  return string.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};

export const TiposDocumentos = ({ response, tipoArchivo }) => {
  let outputFilename;
  if (tipoArchivo === 'excel') {
    outputFilename = `excel_${moment(moment.now()).format(
      'DD-MM-YYYY:HH:mm'
    )}.xlsx`;
  }

  if (tipoArchivo === 'pdf') {
    outputFilename = `reporte_${moment(moment.now()).format(
      'DD-MM-YYYY:HH:mm'
    )}.pdf`;
  }
  const url = URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', outputFilename);
  document.body.appendChild(link);
  link.click();
};


export const exportarDocumento = async ({ params, endpoint, tipoArchivo, method }) => {
  try {
    let response;
    if (method === 'POST') {
      response = await axios({
        url: endpoint,
        method,
        responseType: 'arraybuffer',
        data: {
          ...params,
          exportar: true,
          tipoExportacion: tipoArchivo
        }
      });
    } else {
      response = await axios.get(endpoint, {
        responseType: 'arraybuffer',
        params: {
          ...params,
          exportar: true,
          tipoExportacion: tipoArchivo,
        },
        headers: { 'Content-Type': 'blob' },
      });
    }
    if (response.byteLength > 0) {
      TiposDocumentos({ response, tipoArchivo });
    } else {
      toast.error('Sin registros para exportar');
    }
  } catch (error) {
    return error;
  }
};

export const customCabecero = (selects) => {
  const cabecero = {
    sitio: { width: 4, show: false },
    invernadero: { width: 4, show: false },
    nave: { width: 4, show: false },
    fenologia: { width: 4, show: false },
    tabla: { width: 4, show: false },
    planta: { width: 4, show: false },
    parametro: { width: 4, show: false },
    invernaderoNave: { width: 4, show: false },
    enfermedad: { width: 4, show: false },
    trampa: { width: 4, show: false },
    plaga: { width: 4, show: false },
    surco: { width: 4, show: false },
    saneo: { width: 4, show: false },
    seccion: { width: 4, show: false },
    nivel: { width: 4, show: false },
    nivelInfeccion: { width: 4, show: false },
    semana: { width: 4, show: false },
    dia: { width: 4, show: false },
  };

  return {
    ...cabecero,
    ...selects,
  };
};

export const CamposValidados = (formData) => {
  let esInvalido = true;
  Object.keys(formData).forEach((key) => {
    if (formData[key].length === 0 || formData[key] === '') esInvalido = false;
  });
  return !esInvalido && toast.error(VALIDAR_SELECCION_REPORTE);
};

export const obtenerCentroide = (pts) => {
  if (!pts[0]?.x || !pts[0]?.y) return null;
  var first = pts[0], last = pts[pts.length - 1];
  if (first.x !== last.x || first.y !== last.y) pts.push(first);
  var twicearea = 0,
    x = 0, y = 0,
    nPts = pts.length,
    p1, p2, f;
  for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i]; p2 = pts[j];
    f = (p1.y - first.y) * (p2.x - first.x) - (p2.y - first.y) * (p1.x - first.x);
    twicearea += f;
    x += (p1.x + p2.x - 2 * first.x) * f;
    y += (p1.y + p2.y - 2 * first.y) * f;
  }
  f = twicearea * 3;
  return { x: x / f + first.x, y: y / f + first.y };
};

export const obtenerRangoFechas = (desde, hasta, format = 'W-GGGG', orderAsc = false, hastaFechaActual = true) => {
  const fecha = moment(desde).startOf('isoWeek');
  const fechas = [];
  if (hastaFechaActual) {
    while (fecha.isSameOrBefore(hasta) && fecha.isSameOrBefore(moment())) {
      fechas.push(fecha.format(format));
      fecha.add(1, 'isoWeek');
    }
  } else {
    while (fecha.isSameOrBefore(hasta)) {
      fechas.push(fecha.format(format));
      fecha.add(1, 'isoWeek');
    }
  }
  fechas.sort((a, b) => {
    const fechaA = moment(a, format);
    const fechaB = moment(b, format);
    if (fechaA.isBefore(fechaB)) return orderAsc ? -1 : 1;
    if (fechaA.isAfter(fechaB)) return orderAsc ? 1 : -1;
    return 0;
  });
  return fechas;
};

export const obtenerDiasSemana = (semana = moment().isoWeek(), format = 'W-YYYY', orderAsc = false) => {
  const fecha = moment(semana, 'W (YYYY)').startOf('isoWeek');
  const hasta = moment(fecha).endOf('isoWeek');
  const fechas = [];
  while (fecha.isSameOrBefore(hasta)) {
    fechas.push(fecha.format(format));
    fecha.add(1, 'day');
  }
  fechas.sort((a, b) => {
    const fechaA = moment(a, format);
    const fechaB = moment(b, format);
    if (fechaA.isBefore(fechaB)) return orderAsc ? -1 : 1;
    if (fechaA.isAfter(fechaB)) return orderAsc ? 1 : -1;
    return 0;
  });
  return fechas;
};

export const max = (data) => {
  if (data && data.length > 0) {
    return Math.max(...data);
  }
  return 0;
};

export const min = (data) => {
  if (data && data.length > 0) {
    return Math.min(...data);
  }
  return 0;
};

export const moda = (array, reglas) => {
  const count = array.reduce((acc, curr) => {
    acc[curr] = acc[curr] ? acc[curr] + 1 : 1;
    return acc;
  }, {});

  const maxValue = Math.max(...Object.values(count));
  const modaValor = Object.keys(count).filter(key => count[key] === maxValue);

  if (modaValor.length === 1) {
    return modaValor[0];
  }
  else if (reglas) {
    const copiaModaValor = [...modaValor];
    const modaValorOrdenada = copiaModaValor.sort((a, b) => {
      if (reglas[a] > reglas[b]) return -1;
      if (reglas[a] < reglas[b]) return 1;
      return 0;
    });
    return modaValorOrdenada[0] || 'No definido';
  }
  else {
    return 'No definido';
  }
};

export const generarRecta = (coord1, coord2, n) => {
  const { lat: x1, lng: y1 } = coord1;
  const { lat: x2, lng: y2 } = coord2;
  const cantidad = n - 1;

  const incrementoX = (x2 - x1) / cantidad;
  const incrementoY = (y2 - y1) / cantidad;

  const coordenadas = [];
  for (let i = 0; i <= cantidad; i++) {
    const xI = x1 + i * incrementoX;
    const yI = y1 + i * incrementoY;
    coordenadas.push([xI, yI]);
  }

  coordenadas.push([x2, y2]);

  return coordenadas;
};

/**
 * Función que obtiene los días de una semana
 * @param {number} semana Semana a obtener los días
 * @param {number} anio Año a obtener los días
 * @param {string} [formato] Formato de los días, por defecto YYYY-MM-DD
 * @returns {Array} Días de la semana
 */
export function obtenerDiasDeSemana(semana, anio, formato = 'YYYY-MM-DD') {
  const dias = [];
  const inicioSemana = moment().year(anio).isoWeek(semana).startOf('isoWeek');

  for (let i = 0; i < 7; i++) {
    dias.push(moment(inicioSemana).add(i, 'days').format(formato));
  }

  return dias;
}

/**
 * Función que calcula el área de una delimitación
 * @param {Array} poligono Polígono que representa la delimitación
 * @returns {number} Área de la delimitación
 */
export function calcularAreaDelimitacion(poligono) {
  if (!poligono || poligono.length < 3) return null;

  // Inicializa los valores del cuadro delimitador con las primeras coordenadas del polígono
  let minLat = poligono[0].lat;
  let maxLat = poligono[0].lat;
  let minLng = poligono[0].lng;
  let maxLng = poligono[0].lng;

  // Encuentra los valores máximos y mínimos de latitud y longitud
  poligono.forEach(coord => {
    minLat = Math.min(minLat, coord.lat);
    maxLat = Math.max(maxLat, coord.lat);
    minLng = Math.min(minLng, coord.lng);
    maxLng = Math.max(maxLng, coord.lng);
  });

  // Ajusta los valores del cuadro delimitador para expandir el área
  const margin = 0.01; // Puedes ajustar este margen según tus necesidades
  const expandedBounds = {
    latLngBounds: {
      north: maxLat + margin,
      south: minLat - margin,
      east: maxLng + margin,
      west: minLng - margin
    },
    strictBounds: false,
  };

  return expandedBounds;
};

/**
 * Función para verificar si dos segmentos de línea se intersectan
 * @param {Array<Number>} p1 Punto 1 del segmento 1
 * @param {Array<Number>} q1 Punto 2 del segmento 1
 * @param {Array<Number>} p2 Punto 1 del segmento 2
 * @param {Array<Number>} q2 Punto 2 del segmento 2
 * @returns {boolean} Verdadero si los segmentos se intersectan, falso en caso contrario
 */
export function losSegmentosIntersectan(p1, q1, p2, q2) {
  function orientacion(p, q, r) {
    const val = (q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1]);
    if (val === 0) return 0; // colineales
    return (val > 0) ? 1 : 2; // en sentido horario o antihorario
  }

  function enSegmento(p, q, r) {
    return (Math.min(p[0], q[0]) <= r[0] && r[0] <= Math.max(p[0], q[0]) &&
      Math.min(p[1], q[1]) <= r[1] && r[1] <= Math.max(p[1], q[1]));
  }

  const o1 = orientacion(p1, q1, p2);
  const o2 = orientacion(p1, q1, q2);
  const o3 = orientacion(p2, q2, p1);
  const o4 = orientacion(p2, q2, q1);

  if (o1 !== o2 && o3 !== o4) return true;

  if (o1 === 0 && enSegmento(p1, q1, p2)) return true;
  if (o2 === 0 && enSegmento(p1, q1, q2)) return true;
  if (o3 === 0 && enSegmento(p2, q2, p1)) return true;
  if (o4 === 0 && enSegmento(p2, q2, q1)) return true;

  return false;
}

/**
 * Función para verificar si dos polígonos se intersectan
 * @param {Array<Array<Number>>} poligono1 Polígono 1
 * @param {Array<Array<Number>>} poligono2 Polígono 2
 * @returns {boolean} Verdadero si los polígonos se intersectan, falso en caso contrario
 */
export function losPoligonosIntersectan(poligono1, poligono2) {
  const n = poligono1.length;
  const m = poligono2.length;

  // Verificar intersección entre cada par de lados de los polígonos
  for (let i = 0; i < n; i++) {
    for (let j = 0; j < m; j++) {
      const p1 = poligono1[i];
      const q1 = poligono1[(i + 1) % n];
      const p2 = poligono2[j];
      const q2 = poligono2[(j + 1) % m];

      if (losSegmentosIntersectan(p1, q1, p2, q2)) {
        return true; // Se encontró una intersección entre segmentos
      }
    }
  }

  return false; // No se encontró intersección entre polígonos
}

/**
 * Función para verificar si un poligono está dentro de otro
 * @param {Array<Array<Number>>} poligonoHijo Polígono hijo
 * @param {Array<Array<Number>>} poligonoPadre Polígono padre
 * @returns Retorna verdadero si el polígono hijo está dentro del polígono padre, falso en caso contrario
 */
export function estaPoligonoDentroDeOtro(poligonoHijo, poligonoPadre) {
  return poligonoHijo.every(punto => puntoEnPoligono(punto, poligonoPadre));
}

/**
 * Función que regresa un formato de coordenadas [lat, lng] a partir de un arreglo de objetos.
 * @param {Array<Object>} coordenadas Arreglo de objetos con las coordenadas
 * @param {String} [latKey] Nombre de la propiedad que contiene la latitud. Por defecto 'lat'.
 * @param {String} [lngKey] Nombre de la propiedad que contiene la longitud. Por defecto 'lng'.
 * @param {Boolean} [reverse] Indica si se debe invertir el orden de las coordenadas. Por defecto falso.
 * @returns {Array<Array<Number>>} Arreglo de coordenadas en formato [lng, lat]
 */
export function formatearCoordenadas(coordenadas, latKey = 'lat', lngKey = 'lng', reverse = false) {
  return coordenadas.map(({ [latKey]: lat, [lngKey]: lng }) => reverse ? [lng, lat] : [lat, lng]);
}

/**
 * Función que desnormaliza el formato de temporada
 * @param {Object} data Formato de temporada normalizado
 * @param {Object} data.sitios Sitios de la temporada
 * @param {Object} data.invernaderos Invernaderos de la temporada
 * @param {Object} data.naves Naves de la temporada
 * @returns {Array} Formato de temporada desnormalizado
 */
export function desnormalizarTemporada(data) {
  const { sitios: sitiosBD, invernaderos: invernaderosBD, naves: navesBD } = data;

  const sitios = sitiosBD.allIds.map(id => {
    const sitio = sitiosBD.byId[id];
    const invernaderos = sitio.invernaderos
      .map(id => {
        const invernadero = invernaderosBD.byId[id];
        const naves = invernadero?.naves
          .filter(id => navesBD.byId[id]?.isSelected)
          .map(id => navesBD.byId[id]);

        return {
          ...invernadero,
          naves
        };
      })
      .filter(invernadero => invernadero?.isSelected);

    return {
      ...sitio,
      invernaderos
    };
  });

  return sitios;
}

/**
 * Función que genera un mapa estático a partir de un polígono
 * @param {Object} config Configuración del mapa
 * @param {Object} config.centro Centro del mapa
 * @param {Array<{latitud: number; longitud: number;}>} config.coordenadas Coordenadas del polígono
 * @param {String} [config.size] Tamaño de la imagen
 * @param {String} [config.mapType] Tipo de mapa
 * @returns {String} URL del mapa estático
 */
export function generarMapaEstatico({
  centro,
  coordenadas,
  size = "500x600",
  mapType = "satellite",
}) {
  const baseUrl = "https://maps.googleapis.com/maps/api/staticmap?";

  // Construir el parámetro de path con las coordenadas
  const path = coordenadas.map(coord => `${coord.latitud},${coord.longitud}`).join('|');

  // Color del borde, peso del borde, color de relleno del polígono
  const pathParams = `color:0x0073e6|weight:5|fillcolor:0x0073e6|${path}`;

  // Calcular zoom
  const { bounds } = obtenerLimitesPoligono(coordenadas);
  const zoom = calcularNivelZoom(bounds, 360, 540);

  // Construir la URL completa
  const url = `${baseUrl}center=${centro.latitud},${centro.longitud}&zoom=${zoom}&size=${size}&maptype=${mapType}&path=${pathParams}&key=${config.MAPS_API_KEY}`;

  return url;
}

/**
 * Función que obtiene los límites de un polígono
 * @param {Array<{latitud: number; longitud: number;}>} coordenadas
 * @returns
 */
export function obtenerLimitesPoligono(coordenadas) {
  let minLat = 90, maxLat = -90, minLng = 180, maxLng = -180;

  coordenadas.forEach(coord => {
    if (coord.latitud < minLat) minLat = coord.latitud;
    if (coord.latitud > maxLat) maxLat = coord.latitud;
    if (coord.longitud < minLng) minLng = coord.longitud;
    if (coord.longitud > maxLng) maxLng = coord.longitud;
  });

  const bounds = {
    minLat: minLat,
    maxLat: maxLat,
    minLng: minLng,
    maxLng: maxLng
  };

  const center = {
    latitud: (minLat + maxLat) / 2,
    longitud: (minLng + maxLng) / 2
  };

  return { bounds, center };
}

/**
 * Función que calcula el nivel de zoom para un mapa
 * @param {*} bounds
 * @param {*} mapWidth
 * @param {*} mapHeight
 * @returns
 */
export function calcularNivelZoom(bounds, mapWidth, mapHeight) {
  const WORLD_DIM = { width: 256, height: 256 };
  const ZOOM_MAX = 21;

  function latRad(lat) {
    const sin = Math.sin(lat * Math.PI / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const latFraction = (latRad(bounds.maxLat) - latRad(bounds.minLat)) / Math.PI;
  const lngDiff = bounds.maxLng - bounds.minLng;
  const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

  const latZoom = zoom(mapHeight, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapWidth, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}
