import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';

import Button from '../Button';
import Typography from '../Typography';

import { APP_NAME } from '../../configuraciones/general';
import endpoints from '../../configuraciones/endpoints';
import axios from '../../configuraciones/axios';

import { clearMonitoreoActivo, setSemanaTablero, setSitio, setTemporada } from '../../ducks/tablero';
import { obtenerRangoFechas } from '../../utilidades/functions';
import { NAVE, TABLERO } from '../../constantes/rutas';

import styles, { ComboBox } from './styles';

const HeaderTablero = () => {
  const classes = styles();
  const id = useParams().id;
  const dispatch = useDispatch();

  const { sitioID, sitioOrigenID, semana, temporada } = useSelector(
    store => store.tablero,
    (prev, next) => {
      return (
        prev.sitioID === next.sitioID &&
        prev.sitioOrigenID === next.sitioOrigenID &&
        prev.semana === next.semana &&
        prev.temporada?.fechaInicio === next.temporada?.fechaInicio &&
        prev.temporada?.fechaFin === next.temporada?.fechaFin
      );
    }
  );
  const history = useHistory();

  const [parametros, setParametros] = useState({
    sitiosOrigen: [],
    temporadas: [],
    invernaderos: [],
  });

  const semanas = useMemo(() => {
    return temporada
      ? obtenerRangoFechas(temporada.fechaInicio, temporada.fechaFin, 'W (GGGG)')
        .map((fecha) => ({ id: fecha, nombre: `Semana ${fecha}` }))
      : [];
  }, [temporada]);

  const consultarDatosIniciales = useCallback(async () => {
    const sitiosTemporadas = await axios(endpoints.obtenerTemporadas());

    if (sitioOrigenID) {
      const sitioOrigen = sitiosTemporadas.find(({ id }) => id === sitioOrigenID);
      const temporada = sitioOrigen.temporadas.find(temporada => temporada.sitioID === sitioID);

      const { id, coordenadas, invernaderos } = await axios.get(endpoints.obtenerTemporadas(temporada.id));
      const navesEnSitio = invernaderos.flatMap(({ naves }) => naves);

      setParametros(prev => ({
        ...prev,
        sitiosOrigen: sitiosTemporadas,
        temporadas: sitiosTemporadas
          .map(({ temporadas }) => temporadas)
          .flat()
          .sort((a, b) => new Date(b.fechaInicio) - new Date(a.fechaInicio)),
        invernaderos,
      }));

      batch(() => {
        dispatch(setSitio({ id, coordenadas, navesEnSitio, sitioOrigenID: sitioOrigen.id }));
        dispatch(setTemporada({ ...temporada, mantenerSemana: true }));
        dispatch(clearMonitoreoActivo());
      });
    } else {
      setParametros(prev => ({
        ...prev,
        sitiosOrigen: sitiosTemporadas,
        temporadas: sitiosTemporadas
          .map(({ temporadas }) => temporadas)
          .flat()
          .sort((a, b) => new Date(b.fechaInicio) - new Date(a.fechaInicio)),
      }));
    }
  }, [dispatch, sitioID, sitioOrigenID]);

  const onSeleccionarParametro = useCallback(async (e) => {
    if (!e.target.value) return;

    const name = e.target.name;
    const value = Number(e.target.value);

    switch (name) {
      case "sitioOrigenID": {
        const [temproadaActual] = parametros.sitiosOrigen
          .filter(({ id }) => id === value)
          .flatMap(({ temporadas }) => temporadas)
          .sort((a, b) => new Date(b.fechaInicio) - new Date(a.fechaInicio));

        const { coordenadas, invernaderos } = await axios.get(endpoints.obtenerTemporadas(temproadaActual.id));

        const navesEnSitio = invernaderos.flatMap(({ naves }) => naves);

        setParametros(prev => ({
          ...prev,
          invernaderos,
        }));

        batch(() => {
          dispatch(setSitio({ id: temproadaActual.sitioID, coordenadas, navesEnSitio, sitioOrigenID: value }));
          dispatch(setTemporada(temproadaActual))
          dispatch(clearMonitoreoActivo());
        });
        break;
      }
      case "sitioID": {
        const temporada = parametros.temporadas.find(({ sitioID }) => sitioID === value) || {};

        const { coordenadas, invernaderos } = await axios.get(endpoints.obtenerTemporadas(temporada.id));

        const navesEnSitio = invernaderos.flatMap(({ naves }) => naves);

        setParametros(prev => ({
          ...prev,
          invernaderos,
        }));

        batch(() => {
          dispatch(setSitio({ id: temporada.sitioID, coordenadas, navesEnSitio }));
          dispatch(setTemporada(temporada))
          dispatch(clearMonitoreoActivo());
        });

        setParametros((prev) => ({
          ...prev,
          invernaderos,
        }));

        history.replace(`/${TABLERO}`);
        break;
      }
      case "naveID": {
        history.push(`/${TABLERO}/${NAVE}/${e.target.value}`);
        break;
      }
      case "semana": {
        dispatch(setSemanaTablero(e.target.value));
        break;
      }
      default:
        break;
    }
  }, [dispatch, history, parametros]);

  useEffect(() => {
    consultarDatosIniciales();
  }, [consultarDatosIniciales]);

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar variant="dense">
        <Box flex={1} flexDirection="row" className={classes.toolbarContainer}>
          <Box display="flex" alignItems="center" gridGap={30} flex={1}>
            <Box display="flex" alignItems="center" gridGap={10}>
              <img
                alt="logo"
                src="/assets/images/hoja.png"
                className={classes.logo}
              />
              <Typography className={classes.label} >
                {APP_NAME}
              </Typography>
            </Box>

            {/* Acciones */}
            <Box display="flex" alignItems="center" flex={1} gridGap={1}>
              <ComboBox
                valor={sitioOrigenID}
                opciones={parametros.sitiosOrigen}
                name="sitioOrigenID"
                onChange={onSeleccionarParametro}
                placeholder="Seleccione un sitio"
              />
              <ComboBox
                valueProp="sitioID"
                valor={sitioID}
                opciones={parametros.sitiosOrigen
                  .filter(({ temporadas }) => temporadas.some((temporada) => temporada.sitioID === sitioID ))
                  .flatMap(({ temporadas }) => temporadas)
                }
                name="sitioID"
                onChange={onSeleccionarParametro}
                placeholder="Seleccione una temporada"
                disabled={!sitioOrigenID}
              />
              {sitioID && (
                <>
                  <ComboBox
                    valor={id ? Number(id) : ''}
                    opciones={parametros.invernaderos}
                    name="naveID"
                    onChange={onSeleccionarParametro}
                    grupoKey="naves"
                    placeholder="Seleccione una nave"
                    disabled={!sitioID}
                  />
                  <ComboBox
                    valor={semana}
                    opciones={semanas}
                    name="semana"
                    onChange={onSeleccionarParametro}
                    placeholder="Seleccione una semana"
                    disabled={!sitioID}
                  />
                </>
              )}
            </Box>

            {(id) && (
              <Button
                classesCustom={{ boton: classes.btnSalir }}
                onClick={() => history.replace(`/${TABLERO}`)}
                label="Salir"
              />
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(HeaderTablero);
